/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import Link from 'gatsby-link'
import { css, jsx } from '@emotion/core'

import {
  Page,
  H1,
  Hr,
  // GatsbyLink,
  Box,
  Flex,
  FeatherIcon,
  Text,
} from '../elements'
import { SEO, HeadingStaticPage } from '../components'
import { theme } from '../utils'
// import { Description } from '../components'
// import { breakLongStrings, addInlineLinks } from '../utils'

const PracticalInfoPage = () => (
  <Page>
    {({ t }) => (
      // const str = breakLongStrings({
      //   string: t('practicalInfoText'),
      //   tag: 'p',
      // })
      // const strWithLinks = addInlineLinks(str)

      <React.Fragment>
        <SEO
          {...{
            url: `/practical-info/`,
            title: t('practicalInfo'),
            // description: true,
          }}
        />
        <HeadingStaticPage
          {...{
            icon: 'info',
            bg: 'lightGreen',
            color: 'textAlt',
            title: t('practicalInfo'),
          }}
        />
        <Box.container {...{ py: 0 }}>
          <Text
            {...{
              as: 'p',
              color: 'text',
              className: 'break show-links',

              css: {
                '& table, & td': {
                  border: 'none',
                  width: 'auto',
                  maxWidth: 300,
                },
                '& td': {
                  paddingRight: theme.space[1],
                },
              },

              dangerouslySetInnerHTML: { __html: t('practicalInfoText') },
            }}
          />
        </Box.container>
      </React.Fragment>
    )}
  </Page>
)

export default PracticalInfoPage
